import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const VARIANT_MAP = {
  ON_LIGHT: 'border-green text-green',
  ON_DARK: 'border-white text-white',
};

function CallToAction({ callToAction, variant = 'ON_LIGHT' }) {
  const className = `inline-block px-8 py-1 mt-8 font-bold uppercase border-2 rounded-full ${VARIANT_MAP[variant]}`;
  return (
    <div className="flex flex-wrap items-center space-x-4">
      {callToAction.map((cta) => {
        if (cta._type === 'fileCta') {
          return (
            <a
              key={cta._key}
              href={cta.file.asset.url}
              target="_blank"
              rel="noopener noreferrer"
              className={className}
            >
              {cta.text}
            </a>
          );
        }

        if (cta._type === 'membershipFormCta') {
          return (
            <a
              key={cta._key}
              href={cta.membershipForm.membershipForm.asset.url}
              target="_blank"
              rel="noopener noreferrer"
              className={className}
            >
              {cta.text}
            </a>
          );
        }

        if (cta._type === 'linkCta') {
          return (
            <a
              key={cta._key}
              href={cta.link}
              target="_blank"
              rel="noopener noreferrer"
              className={className}
            >
              {cta.text}
            </a>
          );
        }

        if (cta._type === 'pageCta') {
          return (
            <Link key={cta._key} to={`/${cta.route}/`} className={className}>
              {cta.text}
            </Link>
          );
        }

        return null;
      })}
    </div>
  );
}

CallToAction.propTypes = {
  callToAction: PropTypes.array.isRequired,
  variant: PropTypes.oneOf(['ON_LIGHT', 'ON_DARK']),
};

export { CallToAction };
